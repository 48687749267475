/* eslint-disable no-plusplus */
<template>
    <table>
        <tr
            v-for="(field,ind) in rows"
            :key="`row-${ind}`"
            class=""
        >
            <td
            v-for="(m,j) in field"
            :key="j"
            class="hoverClass"
            >
                <div
                    v-if="m"
                    :id="m.level"
                    :class="{'grid-col': true, 'is-active': selectedCells.includes(m.level)}"
                    :style="`background:${m.color_code}; color:#fff;`"
                    @click="onCellClick(m.level)"
                >
                <!-- @click="getcell(field.num_wheels_per_row, m, n, field.wheel_position_group)" -->
                {{ m.level }}
                </div>
            </td>
        </tr>
    </table>
</template>
<script>
export default {
    props: {
        fields: {
            type: Array,
            required: true,
        },
        cols: {
            type: Number,
            default: 0,
        },
        selectedCells: {
            type: Array,
            required: true,
        },
        type: {
            type: String,
            default: 'wheel',
        },
    },
    data() {
        return {
            rows: [],
        };
    },
    mounted() {
        this.createRows();
    },
    methods: {
        createRows() {
            const maxCols = this.cols;
            const vm = this;
            const rowIndex = this.type === 'wheel' ? 'num_rows' : 'num_rows_forklift';
            const colIndex = this.type === 'wheel' ? 'num_wheels_per_row' : 'num_wheels_per_row_forklift';
            this.fields.forEach(item => {
                let index = 0;
                const numSpace = parseInt((maxCols - item[colIndex]) / 2, 10);
                let temp;
                console.log(numSpace, maxCols);
                for (let j = 0; j < item[rowIndex]; j += 1) {
                    temp = numSpace > 0 ? [...new Array(numSpace).fill(null)] : [];
                    for (let k = 0; k < item[colIndex]; k += 1) {
                        temp.push({
                            level: `${item.wheel_position_group} ${(index += 1)}`,
                            ...item,
                        });
                    }
                    vm.rows.push(temp)
                }
            });
        },
        onCellClick(label) {
            this.$emit('selected', label, this.type);
        },
    },
};
</script>
<style scoped>
  .bg-vue-dark {
    background: #31475e;
  }
  td div.isHover:hover {
    cursor: pointer;
    background: #3D5875 !important;
  }
  .bg-vue-green {
    background: #3fb984;
  }
  td.hoverClass {
    min-width: 60px;
}
td div {
    text-align: center;
}
.is-active {
    font-weight: bold;
    font-style: italic;
    color: black !important;
}
.grid-col {
    cursor: pointer;
}
</style>
